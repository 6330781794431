<h6 class="headh6">Notification Settings</h6>
<div class="bg-design-all tableDataDiv b-top-left m-2">
    <ul>
        <!-- <li>
            <a [routerLink]="'systemNotifications/1'">System Notifications</a>
        </li> -->
        <li>
            <a [routerLink]="'OCRNotifications/2'">OCR Notifications</a>
        </li>
        <!-- <li>
            <a [routerLink]="'ERPNotifications/3'" >ERP Notifications</a>
        </li>
        <li>
            <a [routerLink]="'batchNotifications/4'">Batch Process Notifications</a>
        </li>
        <li>
            <a [routerLink]="'userMangementNotifications/5'">Role/User Management Notifications</a>
        </li>
        <li>
            <a [routerLink]="'exceptionsNotifications/6'">Exceptions Notifications</a>
        </li> -->
        <!-- <li>
            <a [routerLink]="'settingsNotifications/7'">Settings Notifications</a>
        </li> -->
    </ul>
</div>
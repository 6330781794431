<h6 *ngIf="!dashboardViewBoolean" class="headh6">Approval</h6>

<div
  *ngIf="invoiceListBoolean"
  class="container-fluid"
  style="overflow: hidden; max-height: 100vh"
>
  <div>
    <div *ngIf="!dashboardViewBoolean">
      <ul class="nav nav-tabs">
        <li class="nav-item mr-1">
          <a
            class="nav-link"
            [class.active-tab]="viewType == 'vendorInvoice'"
            (click)="chooseApprovalpageTab('vendorInvoice')"
            >Vendor Invoices({{ approvedDataLength }})</a
          >
        </li>
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            [class.active-tab]="viewType == 'serviceInvoice'"
            (click)="chooseApprovalpageTab('serviceInvoice')"
            >Service Invoices({{ approvedDataLengthSP }})</a
          >
        </li> -->
      </ul>

      <div class="positionCreateUpload">
        <!-- <span (click)="filterData()"  class="filter mr-2">
                      <i class="fa fa-sliders" style="transform: rotate(90deg);" aria-hidden="true"></i>
                      <i class="fa fa-sort-desc ml-2" 
                      style="position: relative;top: -3px;" aria-hidden="true"></i>
                    </span> -->
        <button class="btnUpload mr-2" (click)="exportExcel()">
          <i class="fa fa-download" aria-hidden="true"></i>
        </button>
        <!-- <button class=" btnUpload mr-2" (click)="toCreateNew()"><span class="f-16">+</span> Create</button> -->
      </div>
      <!-- <div class="filter_input">
        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [showIcon]="true" placeholder="Select dates to Filter" [readonlyInput]="true" inputId="range"></p-calendar>
        <button class="btnUpload filter_btn_m "> Filter</button>
    </div> -->
    </div>

    <div class="tableDataDiv bg-design-all pt-1" [ngSwitch]="viewType">
      <!-- vendor invoices Tab -->
      <div *ngSwitchCase="'vendorInvoice'">
        <div class="tableDataDiv">
          <div>
            <div *ngIf="!dashboardViewBoolean" class="searchBar">
              <input
                class="searchInput"
                pInputText
                type="text"
                (keyup)="searchImport($event.target.value)"
                placeholder="Search..."
                (input)="approve.filterGlobal($event.target.value, 'contains')"
              />
              <span class="material-icons searchIcon"> search </span>
            </div>
            <p-table
              #approve
              [value]="approvedData"
              [rows]="rows"
              [first]="first"
              [paginator]="showPaginatorApproved"
              (onPage)="paginateVendor($event)"
              [showCurrentPageReport]="true"
              [columns]="ApprovedColumn"
              styleClass="p-datatable-striped"
              [globalFilterFields]="approvedColumnField"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[10, 25, 50]"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    *ngFor="let col of columns"
                    [pSortableColumn]="col.field"
                    [pTooltip]="col.header"
                    tooltipPosition="top"
                  >
                    {{ col.header }}
                  </th>
                  <th
                    *ngIf="!dashboardViewBoolean"
                    class="action_Zindex"
                    style="width: 6%"
                  >
                    Actions
                  </th>
                </tr>
                <tr>
                  <td *ngFor="let col of columns">
                    <input
                      pInputText
                      type="text"
                      (input)="
                        approve.filter(
                          $event.target.value || ' ',
                          col.field,
                          'contains'
                        )
                      "
                      [value]="approve.filters[col.field]?.value"
                      [placeholder]="'Search by ' + col.header"
                      class="tableSearchInput"
                    />
                  </td>
                  <td></td>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-invoiceData
                let-columns="columns"
              >
                <tr>
                  <td
                    *ngFor="let col of columns"
                    [title]="invoiceData[col.field]"
                  >
                    {{ invoiceData[col.field] }}
                  </td>
                  <td *ngIf="!dashboardViewBoolean">
                    <button
                      class="b-0 actionsBtn"
                      [disabled]="!editPermissionBoolean"
                      [ngStyle]="{
                        cursor: !editPermissionBoolean
                          ? 'not-allowed'
                          : 'pointer'
                      }"
                      (click)="editInvoice(invoiceData)"
                    >
                      <i class="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="ColumnLengthVendor" class="p-t-30">
                    <img
                      src="assets/Group 2691.png"
                      width="260"
                      alt="imageUrl"
                    />
                    <br />
                    No Invoices found.
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="paginatorleft"> </ng-template>
              <ng-template pTemplate="paginatorright"> </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <!-- Service invoices Tab -->
      <div *ngSwitchCase="'serviceInvoice'">
        <div *ngIf="!dashboardViewBoolean" class="tableDataDiv">
          <div>
            <div class="searchBar">
              <input
                class="searchInput"
                pInputText
                type="text"
                (keyup)="searchImport($event.target.value)"
                placeholder="Search..."
                (input)="approve.filterGlobal($event.target.value, 'contains')"
              />
              <span class="material-icons searchIcon"> search </span>
            </div>
            <p-table
              #approveSP
              [value]="approvedDataSP"
              [rows]="rows_service"
              [first]="first_service"
              [paginator]="showPaginatorApprovedSP"
              (onPage)="paginateService($event)"
              [showCurrentPageReport]="true"
              [columns]="ApprovedColumnSP"
              styleClass="p-datatable-striped"
              [globalFilterFields]="approvedColumnField"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[10, 25, 50]"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    *ngFor="let col of columns"
                    [pSortableColumn]="col.field"
                    [pTooltip]="col.header"
                    tooltipPosition="top"
                  >
                    {{ col.header }}
                  </th>
                  <th class="action_Zindex" style="width: 6%">Actions</th>
                </tr>
                <tr>
                  <td *ngFor="let col of columns">
                    <input
                      pInputText
                      type="text"
                      (input)="
                        approveSP.filter(
                          $event.target.value || ' ',
                          col.field,
                          'contains'
                        )
                      "
                      [value]="approveSP.filters[col.field]?.value"
                      [placeholder]="'Search by ' + col.header"
                      class="tableSearchInput"
                    />
                  </td>
                  <td></td>
                </tr>
              </ng-template>

              <ng-template
                pTemplate="body"
                let-invoiceData
                let-columns="columns"
              >
                <tr>
                  <td
                    *ngFor="let col of columns"
                    [title]="invoiceData[col.field]"
                  >
                    {{ invoiceData[col.field] }}
                  </td>
                  <td>
                    <!-- <i class="pi pi-eye f-16 showEye mr-2" (click)="viewInvoice(invoiceData)"></i> -->
                    <button
                      class="b-0 actionsBtn"
                      [disabled]="!editPermissionBoolean"
                      [ngStyle]="{
                        cursor: !editPermissionBoolean
                          ? 'not-allowed'
                          : 'pointer'
                      }"
                      (click)="editInvoice(invoiceData)"
                    >
                      <i class="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="ColumnLengthSP" class="p-t-30">
                    <img
                      src="assets/Group 2691.png"
                      width="260"
                      alt="imageUrl"
                    />
                    <br />
                    No Invoices found.
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="paginatorleft"> </ng-template>
              <ng-template pTemplate="paginatorright"> </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

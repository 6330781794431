<div>
  <ul class="nav nav-pills">
    <li class="nav-item mr-1">
      <a
        class="nav-link"
        [class.active-pill]="viewType == 'Process'"
        (click)="choosepageTab('Process')"
      >
        Process Reports</a
      >
    </li>
    <li class="nav-item mr-1">
      <a
        class="nav-link"
        [class.active-pill]="viewType == 'Exception'"
        (click)="choosepageTab('Exception')"
      >
        Exception Reports</a
      >
    </li>
    <li class="nav-item mr-1">
      <a
        class="nav-link"
        [class.active-pill]="viewType == 'complete'"
        (click)="choosepageTab('complete')"
      >
        Complete Reports</a
      >
    </li>

    <li class="nav-item">
      <a
        class="nav-link ml-1"
        [class.active-pill]="viewType == 'emailException'"
        (click)="choosepageTab('emailException')"
      >
      Email Reports</a
      >
    </li>

    <li class="nav-item">
      <a
        class="nav-link ml-1"
        [class.active-pill]="viewType == 'onboarded'"
        (click)="choosepageTab('onboarded')"
      >
      Onboarded Reports</a
      >
    </li>
  </ul>
  <div *ngIf=" viewType == 'onboarded'"> <button class="btnUpload dwnldBtn" (click)="downloadReport()">Download Report</button></div>
  <div class="filter_inputDash" *ngIf="viewType == 'emailException'">
    <p-calendar
      [(ngModel)]="rangeDates"
      selectionMode="range"
      [minDate]="minDate"
      [maxDate]="maxDate"
      placeholder="Select dates to Filter"
      [readonlyInput]="true"
      [showIcon]="true"
      showButtonBar="true"
      inputId="range"
      (onClearClick)="clearDates()"
    ></p-calendar>

    <button class="btnUpload filter_btn_m" (click)="filterByDate(rangeDates)">Filter</button>
  </div>
  <div class="filter_inputDash" *ngIf=" viewType == 'onboarded'">
    <p-calendar [(ngModel)]="selectDate" view="month" dateFormat="mm" [showIcon]="true" [yearNavigator]="false" [minDate]="minDate" 
    [maxDate]="maxDate" [yearRange]='displayYear' placeholder="Select dates to filter.." [readonlyInput]="false"
    inputId="monthpicker"></p-calendar>

    <button class="btnUpload filter_btn_m " (click)="applyMonthfilter(selectDate)"> Filter</button>
  </div>

  <div  [ngSwitch]="viewType" style="padding-top: 12px">
    <!-- Process invoices Tab -->
    <div *ngSwitchCase="'Process'">
      <app-process-reports [reportType]="'Process'"></app-process-reports>
    </div>

    <div *ngSwitchCase="'complete'">
      <app-process-reports [reportType]="'complete'"></app-process-reports>
    </div>
    <!-- Exception invoices Tab -->
    <div *ngSwitchCase="'Exception'" class="tableDataDiv">
      <app-exception-reports></app-exception-reports>
    </div>

    <!-- Exception invoices Tab -->
    <div *ngSwitchCase="'emailException'" class="tableDataDiv">
      <app-table
        [tableData]="exceptionTableData"
        [invoiceColumns]="columnsForException"
        [showPaginator]="showPaginatorException"
        [columnsToFilter]="exceptionColumnField"
        [columnLength]="ColumnLengthException"
      >
      </app-table>
    </div>

    <!-- Onboarding Data Tab -->
    <div *ngSwitchCase="'onboarded'" class="tableDataDiv">
      <app-table
        [tableData]="onboardTableData"
        [invoiceColumns]="columnsForonboard"
        [showPaginator]="showPaginatoronboard"
        [columnsToFilter]="onboardColumnField"
        [columnLength]="ColumnLengthonboard"
      >
      </app-table>
    </div>

    
  </div>
</div>

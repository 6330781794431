import { ImportExcelService } from './../../services/importExcel/import-excel.service';
import { element } from 'protractor';
import { DocumentService } from './../../services/vendorPortal/document.service';

import { environment } from './../../../environments/environment';
import { startWith } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { TaggingService } from './../../services/tagging.service';
import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { FileUploader } from 'ng2-file-upload';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import {
  HttpEventType,
  HttpErrorResponse
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";

// import { moveItemInList } from 'mdb-sortable';

import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig
} from "primeng/api";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/dataStore/data.service';
import { DateFilterService } from 'src/app/services/date/date-filter.service';

const EventSource: any = window['EventSource'];

export interface UserData {
  invoiceId: number;
  poNumber: number;
  VenderId: string;
  Vendername: string;
  entity: string;
  uploaded: string;
  modified: string;
  status: string;
  amount: string;
  date: string;
}

export interface updateColumn {
  idtabColumn: number;
  ColumnPos: number;
  isActive: boolean;
}

const URL = `/${environment.apiVersion}/VendorPortal/uploadfile`

@Component({
  selector: 'app-document-status',
  templateUrl: './document-status.component.html',
  styleUrls: ['./document-status.component.scss']
})
export class DocumentStatusComponent implements OnInit {
  displayInvoicePage: boolean = true;
  activeMenuName: string = "invoice";
  users: UserData[];
  showPaginator: boolean;
  invoiceDispalyData: any[];
  allInvoiceLength: number;
  showPaginatorAllInvoice: boolean;
  createInvoice: boolean;
  allSearchInvoiceString = [];
  visibleSidebar2: boolean;
  cols: any;
  invoiceColumns: any;
  poColumns: any;
  archivedColumns: any;
  allColumns: any;
  columnstodisplayInvoice: any[];
  columnstodisplayPO: any[];
  columnstodisplayArchived: any[];

  uplaodInvoiceDialog: boolean;
  selectedPONumber;
  filteredPO: any[];
  poNumbersList = ['PO546008', 'PO235355', 'PO345345', 'PO775468', 'PO432225'];
  // currentUser = JSON.parse(localStorage.getItem('currentLoginUser'));

  updateColumns: updateColumn[] = [];
  public uploader: FileUploader = new FileUploader({
    isHTML5: true
  });
  public hasBaseDropZoneOver: boolean = false;
  isDown: boolean = false;
  isuploadable: boolean = true;
  url: any;
  dragfile: boolean;
  name: any;
  type: any;
  size: any;
  isPdf: boolean;
  showInvoice: any;
  response: string;
  invoiceUploadDetails: any;
  poDispalyData: any[];
  poArrayLength: number;
  GRNDispalyData: any[];
  GRNArrayLength: number;
  receiptArrayLength: any = 0;
  receiptDispalyData: any[];
  archivedDisplayData: any[] = [];
  archivedArrayLength: number;
  progress: number;
  displaySelectPdfBoolean: boolean;
  OCRInput: string;
  OcrProgress: number;
  progressEvent;
  progressText: string = '...initializing';
  updateData: {};
  progressWidth: string;
  showPaginatorGRNTable: boolean;
  showPaginatorPOTable: boolean;
  showPaginatorPaymentTable:boolean;
  minDate: Date;
  maxDate: Date;
  rangeDates: Date[];
  allINVColumnLength: any;
  POColumnLength: any;
  ARCColumnLength: any;
  constructor(
    public route: Router,
    private datePipe: DatePipe,
    private http: HttpClient,
    private docService: DocumentService,
    private messageService: MessageService,
    private  sharedService : SharedService,
    private dataService: DataService,
    private tagService: TaggingService,
    private dateFilterService : DateFilterService,
    private ImportExcelService: ImportExcelService) {
    // this.uploader = new FileUploader({
    //   url: URL,
    //   authToken: `Bearer ${this,this.currentUser.token}`,
    //   disableMultipart: true,
    //   formatDataFunctionIsAsync: true,
    //   formatDataFunction: async (item) => {
    //     let pdfInvoice = new FormData()
    //     pdfInvoice.append('file',item._file.name)
    //     return new Promise( (resolve, reject) => {
    //       resolve(
    //         // pdfInvoice
    //         {
    //         file: item._files,
    //         // length: item._file.size,
    //         // contentType: item._file.type,
    //         // date: new Date()

    //       }
    //       );
    //     });
    //   }
    // });

    // this.hasBaseDropZoneOver = false;

    // this.response = '';

    // this.uploader.response.subscribe( res => this.response = res );
  }

  ngOnInit(): void {
    // this.activeMenuName = this.tagService.activeMenuName
    // this.uploader.onBeforeUploadItem = (fileItem: any) => {
    //   fileItem.formData.push( { file: this.someValue } );
    //  };
    // this.invoiceDispalyData = this.dataService.VendorPortalinvoiceLoadedData;
    // this.allInvoiceLength = this.dataService.VendorPortalinvoiceLoadedData.length;
    // this.poDispalyData = this.dataService.poLoadedData;
    // this.poArrayLength = this.dataService.poLoadedData.length;
    // this.GRNDispalyData = this.dataService.GRNLoadedData;
    // this.GRNArrayLength = this.dataService.GRNLoadedData.length;
    // this.receiptDispalyData = this.dataService.receiptLoadedData
    // this.receiptArrayLength = this.dataService.receiptLoadedData.length;
    // // this.activeMenuName = this.tagService.activeMenuName
    // this.visibleSidebar2 = this.docService.sidebarBoolean;
    // if (this.dataService.VendorPortalinvoiceLoadedData.length == 0) {
    //   this.getInvoiceData();
    // }
    // if (this.dataService.poLoadedData.length == 0) {
    //   this.getDisplayPOData();
    // }
    // if (this.dataService.GRNLoadedData.length == 0) {
    //   this.getDisplayGRNdata();
    // }
    // if (this.dataService.receiptLoadedData.length == 0) {
    //   this.getDisplayReceiptdata();
    // }
    this.getInvoiceData();
    this.getDisplayPOData();
    this.getDisplayGRNdata();
    this.getDisplayReceiptdata();
    this.getInvoiceColumns();
    this.getPOColums();
    this.getArchivedColumns();
    this.dateRange();
  }


  dateRange(){
    this.dateFilterService.dateRange();
    this.minDate = this.dateFilterService.minDate;
    this.maxDate = this.dateFilterService.maxDate;
  }
  getInvoiceData() {
    this.sharedService.getAllInvoice().subscribe((data: any) => {
      const invoicePushedArray = [];
      console.log(data)
      if (data) {
        data.ok.Documentdata.forEach(element => {
          let invoiceData = { ...element.Document, ...element.Entity, ...element.EntityBody, ...element.ServiceProvider, ...element.ServiceAccount, ...element.VendorAccount, ...element.Vendor }
          invoiceData.docstatus = element.docstatus;
          invoicePushedArray.push(invoiceData)
        });
        this.invoiceDispalyData = invoicePushedArray;
        this.dataService.VendorPortalinvoiceLoadedData = invoicePushedArray;
        console.log(this.invoiceDispalyData)
        this.allInvoiceLength = this.invoiceDispalyData.length;
        if (this.invoiceDispalyData.length > 10) {
          this.showPaginatorAllInvoice = true;
        }
      }
    }, error => {
      alert(error.statusText);
    })
  }

  getDisplayPOData() {
    this.sharedService.getPOData('').subscribe((data: any) => {
      const poPushedArray = [];
      if (data.ok) {
        data.ok.podata.forEach(element => {
          let poData = { ...element.Document, ...element.Entity, ...element.EntityBody, ...element.ServiceProvider, ...element.ServiceAccount, ...element.VendorAccount, ...element.Vendor }
          poData.docstatus = element.docstatus;
          poPushedArray.push(poData)
        });
      }
        this.poDispalyData = poPushedArray;
        this.dataService.VendorPortalpoLoadedData = poPushedArray;
        this.poArrayLength = this.poDispalyData.length;
        if(this.poArrayLength >10){
          this.showPaginatorPOTable = true
        }
      console.log(this.poDispalyData)
    })
  }

  getDisplayGRNdata() {
    this.sharedService.getGRNdata('').subscribe((data: any) => {
      this.GRNDispalyData = data.grndata;
      this.dataService.VendorPortalGRNLoadedData = data.grndata;
      this.GRNArrayLength = this.GRNDispalyData.length;
      if(this.GRNArrayLength > 10){
        this.showPaginatorGRNTable = true
      }
    })
  }
  getDisplayReceiptdata() {
    // this.docService.getAllInvoice('4').subscribe((data: any) => {
    //   const receiptPushedArray = [];
    //   if (data.ok) {
    //     data.ok.forEach(element => {
    //       let receiptData = { ...element.Invoice, ...element.Entity, ...element.EntityBody, ...element.ServiceProvider, ...element.ServiceAccount, ...element.VendorAccount, ...element.Vendor }
    //       receiptPushedArray.push(receiptData)
    //     });
    //   }
    //   this.receiptDispalyData = receiptPushedArray;
    //   this.receiptArrayLength = this.receiptDispalyData.length
    //   console.log(this.receiptDispalyData)
    // }, error => {
    //   alert(error.statusText);
    // })
  }
  getInvoiceColumns() {
    // this.SpinnerService.show();
    this.updateColumns = [];
    this.sharedService.readColumnInvoice('INV').subscribe((data: any) => {
      const pushedInvoiceColumnsArray = []
      data.col_data.forEach(element => {
        let arrayColumn = { ...element.DocumentColumnPos, ...element.ColumnPosDef, };
        pushedInvoiceColumnsArray.push(arrayColumn)
      });
      this.invoiceColumns = pushedInvoiceColumnsArray.filter(ele => {
        return ele.isActive == 1
      })
      const arrayOfColumnId = []
      this.invoiceColumns.forEach(e => {
        arrayOfColumnId.push(e.dbColumnname);
      });
      this.columnstodisplayInvoice = arrayOfColumnId;
      // this.allColumns = pushedInvoiceColumnsArray;
      this.invoiceColumns = this.invoiceColumns.sort((a, b) => a.documentColumnPos - b.documentColumnPos);
      this.allINVColumnLength = this.invoiceColumns.length + 1 ;
      this.allColumns = pushedInvoiceColumnsArray.sort((a, b) => a.documentColumnPos - b.documentColumnPos)
      console.log(this.allColumns);
      this.allColumns.forEach(val => {
        let activeBoolean;
        if (val.isActive == 1) {
          activeBoolean = true
        } else {
          activeBoolean = false
        }
        this.updateColumns.push({ idtabColumn: val.idDocumentColumn, ColumnPos: val.documentColumnPos, isActive: activeBoolean })

      });
      
      // this.SpinnerService.hide();
      console.log(this.updateColumns)
      // this.invoiceColumns= pushedInvoiceColumnsArray;
    }, error => {
      alert(error.statusText);
    })
  }

  getPOColums() {
    this.updateColumns = [];
    this.sharedService.readColumnInvoice('PO').subscribe((data: any) => {
      const pushedPOColumnsArray = []
      data.col_data.forEach(element => {
        let arrayColumn = { ...element.ColumnPosDef, ...element.DocumentColumnPos };
        pushedPOColumnsArray.push(arrayColumn)
      });
      this.poColumns = pushedPOColumnsArray.filter(element => {
        return element.isActive == 1
      })
      console.log(this.poColumns)
      const arrayOfColumnIdPO = []
      this.poColumns.forEach(e => {
        arrayOfColumnIdPO.push(e.dbColumnname);
      });
      this.columnstodisplayPO = arrayOfColumnIdPO;
      this.poColumns = this.poColumns.sort((a, b) => a.documentColumnPos - b.documentColumnPos);
      this.POColumnLength = this.poColumns.length + 1 ;
      this.allColumns = pushedPOColumnsArray.sort((a, b) => a.documentColumnPos - b.documentColumnPos)
      this.allColumns.forEach(val => {
        let activeBoolean;
        if (val.isActive == 1) {
          activeBoolean = true
        } else {
          activeBoolean = false
        }
        this.updateColumns.push({ idtabColumn: val.idDocumentColumn, ColumnPos: val.documentColumnPos, isActive: activeBoolean })

      });
      console.log(this.updateColumns)
      // console.log(res)
      // console.log(pushedPOColumnsArray);
      // this.allColumns = pushedPOColumnsArray;
    }, error => {
      alert(error.statusText);
    })
  }
  getArchivedColumns() {
    this.updateColumns = [];
    this.sharedService.readColumnInvoice('ARC').subscribe((data: any) => {
      const pushedArchivedColumnsArray = []
      data.col_data.forEach(element => {
        let arrayColumn = { ...element.DocumentColumnPos, ...element.ColumnPosDef };
        pushedArchivedColumnsArray.push(arrayColumn)
      });
      this.archivedColumns = pushedArchivedColumnsArray.filter(element => {
        return element.isActive == 1
      })
      const arrayOfColumnIdArchived = []
      this.archivedColumns.forEach(e => {
        arrayOfColumnIdArchived.push(e.dbColumnname);
      });
      this.columnstodisplayArchived = arrayOfColumnIdArchived;
      this.allColumns = pushedArchivedColumnsArray.sort((a, b) => a.documentColumnPos - b.documentColumnPos);
      this.archivedColumns = this.archivedColumns.sort((a, b) => a.documentColumnPos - b.documentColumnPos);
      this.ARCColumnLength = this.archivedColumns.length + 1 ;
      // this.allColumns = pushedPOColumnsArray
      this.allColumns.forEach(val => {
        let activeBoolean;
        if (val.isActive == 1) {
          activeBoolean = true
        } else {
          activeBoolean = false
        }
        this.updateColumns.push({ idtabColumn: val.idDocumentColumn, ColumnPos: val.documentColumnPos, isActive: activeBoolean })

      });
      console.log(this.updateColumns)
    }, error => {
      alert(error.statusText);
    })
  }

  menuChange(value) {
    this.updateColumns = []
    // this.tagService.activeMenuName = value;
    this.activeMenuName = value
    // this.getInvoiceData();

    if (value == 'invoice') {
      this.route.navigate(['/vendorPortal/invoice/allInvoices']);
      this.getInvoiceColumns();
    } else if (value == 'po') {
      this.getPOColums();
      this.route.navigate(['/vendorPortal/invoice/PO']);
    } else if (value == 'grn') {
      this.route.navigate(['/vendorPortal/invoice/GRN']);
    } else if (value == 'pip') {
      this.route.navigate(['/vendorPortal/invoice/paymentInprogress']);
    } else if (value == 'archived') {
      this.getArchivedColumns();
      this.route.navigate(['/vendorPortal/invoice/archived']);
    }
  }

  searchInvoiceDataV(toSearch) {
    console.log(toSearch)
    console.log(toSearch.filteredValue)

    this.allSearchInvoiceString = toSearch.filteredValue;

    if (this.route.url == '/vendorPortal/invoice/allInvoices') {

      console.log(this.allSearchInvoiceString);
    } else if (this.route.url == '/vendorPortal/invoice/PO') {

    } else if (this.route.url == '/vendorPortal/invoice/archived') {

    }
  }
  showSidebar(value) {
    this.visibleSidebar2 = value;
    if (this.route.url == '/vendorPortal/invoice/allInvoices') {
      this.getInvoiceColumns();
    } else if (this.route.url == '/vendorPortal/invoice/PO') {
      this.getPOColums();
    } else if (this.route.url == '/vendorPortal/invoice/archived') {
      this.getArchivedColumns();
    }
  }
  exportExcel() {
    console.log(this.allSearchInvoiceString)
    if (this.allSearchInvoiceString && this.allSearchInvoiceString.length > 0) {
      this.ImportExcelService.exportExcel(this.allSearchInvoiceString);
    } else {
      alert("No Data to import")
    }
  }

  onOptionDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.allColumns, event.previousIndex, event.currentIndex);
    if (this.route.url == '/vendorPortal/invoice/allInvoices') {
      this.allColumns.forEach((e, index) => {
        this.updateColumns.forEach(val => {
          if (val.idtabColumn === e.idDocumentColumn) {
            val.ColumnPos = index + 1;
          }
        })
      })
    }
    else if (this.route.url == '/vendorPortal/invoice/PO') {
      this.allColumns.forEach((e, index) => {
        this.updateColumns.forEach(val => {
          if (val.idtabColumn === e.idDocumentColumn) {
            val.ColumnPos = index + 1;
          }
        })
      })
    }
    else if (this.route.url == '/vendorPortal/invoice/archived') {
      this.allColumns.forEach((e, index) => {
        this.updateColumns.forEach(val => {
          if (val.idtabColumn === e.idDocumentColumn) {
            val.ColumnPos = index + 1;
          }
        })
      })
    }

  }
  order(v) {
    console.log(v)
  }
  activeColumn(e, value) {
    if (this.route.url == '/vendorPortal/invoice/allInvoices') {
      this.updateColumns.forEach(val => {
        if (val.idtabColumn == value.idDocumentColumn) {
          val.isActive = e.target.checked
        }
      })
    } else if (this.route.url == '/vendorPortal/invoice/PO') {
      this.updateColumns.forEach(val => {
        if (val.idtabColumn == value.idDocumentColumn) {
          val.isActive = e.target.checked
        }
      })
    } else if (this.route.url == '/vendorPortal/invoice/archived') {
      this.updateColumns.forEach(val => {
        if (val.idtabColumn == value.idDocumentColumn) {
          val.isActive = e.target.checked
        }
      })
    }

  }

  updateColumnPosition() {
    if (this.route.url == '/vendorPortal/invoice/allInvoices') {
      this.sharedService.updateColumnPOs(this.updateColumns, 'INV').subscribe((data: any) => {
        console.log(data);
        this.getInvoiceColumns();
      }, error => {
        alert(error.statusText);
      })
    } else if (this.route.url == '/vendorPortal/invoice/PO') {
      console.log(this.updateColumns)
      this.sharedService.updateColumnPOs(this.updateColumns, 'PO').subscribe((data: any) => {
        console.log(data);
        this.getPOColums();
      }, error => {
        alert(error.statusText);
      })
    } else if (this.route.url == '/vendorPortal/invoice/archived') {
      this.sharedService.updateColumnPOs(this.updateColumns, 'ARC').subscribe((data: any) => {
        console.log(data);
        this.getArchivedColumns();
      }, error => {
        alert(error.statusText);
      })

    }
    this.visibleSidebar2 = false;
  }



}


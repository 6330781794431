// import { environment } from './../../../../../../Utility-tool/utilityBase/src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { AuthenticationService } from 'src/app/services/auth/auth-service.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment, environment1 } from 'src/environments/environment.prod';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
interface IPData {
  ip: string;
}
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  emailId: string;
  password: any;
  newPassword: any;
  sendMail: string;
  confirmPassword: any;
  passwordMatchBoolean: boolean;
  fieldTextType: boolean;
  fieldTextTypeReset1: boolean;
  fieldTextTypeReset: boolean;
  loginboolean: boolean = true;
  forgotboolean: boolean = false;
  resetPassword: boolean = false;
  successPassword: boolean = false;
  loginBooleanSend: boolean = false;

  keepMeLogin: boolean = false;
  otp: string;
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '35px'
    }
  };
  userDetails = [
    { 'userId': 'prathapDS', 'password': '12345678', 'AccountType': 'customer' },
    { 'userId': 'DS2021', 'password': '12345678', 'AccountType': 'vendor' }
  ]
  showOtpPanel: boolean;
  showSendbtn: boolean = true;
  showVerifyBtn: boolean = false;
  otpData: any;
  paswrd: any;

  returnUrl: string;
  error = '';
  token: any;
  popupText: any;
  alertDivBoolean: boolean;
  submitted: boolean = false;
  User_type: string;
  errorMail: boolean;
  errorMailText: any;
  tokenOTP: any;
  current_year: number;


  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,private msalService: MsalService,private googleService: SocialAuthService) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.User_type = this.authenticationService.currentUserValue.user_type;
      if (this.User_type === 'customer_portal') {
        this.router.navigate(['/customer']);
      } else if (this.User_type === 'vendor_portal') {
        this.router.navigate(['/vendorPortal']);
      }
    }
  }

  ngOnInit(): void {
    let today = new Date();
    this.current_year = today.getFullYear();
    this.msalService.instance.handleRedirectPromise();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextTypeReset() {
    this.fieldTextTypeReset = !this.fieldTextTypeReset;
  }
  toggleFieldTextTypeReset1() {
    this.fieldTextTypeReset1 = !this.fieldTextTypeReset1;
  }
  test(event) {
    if (this.newPassword == this.confirmPassword) {
      this.passwordMatchBoolean = false;
    } else {
      // alert('Enter same password')
      this.passwordMatchBoolean = true;
    }
  }
  forgot() {
    this.loginboolean = false;
    this.forgotboolean = true;
    this.resetPassword = false;
    this.successPassword = false;
  }
  tryLogin() {
    this.loginboolean = true;
    this.forgotboolean = false;
    this.resetPassword = false;
    this.successPassword = false;
    this.showOtpPanel = false;
    this.showSendbtn = true;
  }
  sendOtp() {
    this.loading = true;
    let mailData = {
      mail: [this.sendMail]
    }
    this.sharedService.sendMail(this.sendMail).subscribe((data) => {
      this.tokenOTP = data.token;
      if (data.result == "successful") {
        this.loading = false;
        this.showOtpPanel = true;
        this.showSendbtn = false;
        this.loginboolean = false;
        this.forgotboolean = false;
        this.resetPassword = true;
        this.successPassword = false;
        this.errorMail = false;
      }
    },err =>{
      this.errorMail = true;
      this.errorMailText = err.statusText
      this.loading = false;
    })
  }
  verifyOtp() {
    
    // this.loading = true;
    // this.sharedService.verifyotp().subscribe((data) => {
    //   this.loading = false;
    //   if (data['OTP'] == this.otp) {
    //     this.loginboolean = false;
    //     this.forgotboolean = false;
    //     this.resetPassword = true;
    //     this.successPassword = false;
    //     this.showSendbtn = true;
    //     this.showOtpPanel = true;
    //   } else {
    //     alert("Enter valid OTP");
    //     this.showSendbtn = true;
    //     this.showOtpPanel = false;
    //   }
    //   console.warn(data['OTP'])
    //   this.otpData = data['OTP'];
    // })
    // console.log(this.otpData)
  }
  resetPass() {

    this.loading = true;
    let updatePassword = {
      "activation_code": this.tokenOTP,
      "password": this.paswrd
    } 
    this.sharedService.updatepass(JSON.stringify(updatePassword),this.otp).subscribe(data => {
      this.loading = false;
      this.loginboolean = false;
      this.forgotboolean = false;
      this.resetPassword = false;
      this.successPassword = true;

    })
  }
  resetSuccess() {
    this.loginboolean = true;
    this.forgotboolean = false;
    this.resetPassword = false;
    this.successPassword = false;
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; };
  loginMS(){
    this.msalService.loginPopup().subscribe((response: AuthenticationResult) => {
      this.msalService.instance.setActiveAccount(response.account);
      this.login('ms');
    });
  }
  signInWithGoogle(): void {
    this.googleService.signIn(GoogleLoginProvider.PROVIDER_ID).then((response:any) =>{
      localStorage.setItem("ga.account.keys", response["email"]);
      this.login('ga');
    });
  }
  isLoggedInMs(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }
  login(type) {
    this.submitted = true;
    if(type == "ms"){
      this.f.username.setValue(this.msalService.instance.getActiveAccount().username);
      this.f.password.setValue("ms");
    }else if(type == "ga"){
      this.f.username.setValue(localStorage.getItem("ga.account.keys"));
      this.f.password.setValue("ga");
    }
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    let data1 = {
      "username": this.f.username.value,
      "password": this.f.password.value,
      "type":type
    }
    localStorage.setItem('username',JSON.stringify(data1.username));
    this.authenticationService.login(JSON.stringify(data1))
      .subscribe(
        data => {
          this.loading = false;
          this.getIPAddress();
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          } else if (data.user_type === 'customer_portal') {
            let route = '';
            if(data.userdetails?.landingPage == 'Upload'){
              route = '/uploadInvoices'
            } else if(data.userdetails?.landingPage == 'Document Status'){
              route = '/invoice/allInvoices'
            } else if(data.userdetails?.landingPage == 'Dashboard'){
              route = '/home'
            } else if(data.userdetails?.landingPage == 'Exception'){
              route = '/ExceptionManagement'
            }
            this.router.navigate([`/customer${route}`]);
          } else if (data.user_type === 'vendor_portal') {
            this.router.navigate(['/vendorPortal']);
          }
          environment1.username = data1.username;
          // window.location.reload();
        },
        error => {
          this.loading = false;
          if (error.status === 401 || error.status === 404) {
            if(error.status === 401){
              this.error = "Username or/and password are incorrect.";
            }else{
              this.error = "User is not Registered!";
            }
            this.alertDivBoolean = true
          } else {
            this.error = "Username or/and password are incorrect.";
          }

        });
  }
  storeUser(e) {
    this.keepMeLogin = e.target.checked;
    this.sharedService.keepLogin = e.target.checked;
  }
  onOtpChange(otp) {
    this.otp = otp;
  }
  async getIPAddress() {
    // const response = await fetch('https://api.ipify.org/?format=json');
    // const data = await response.json();
    // localStorage.setItem('userIp', JSON.stringify(data.ip));
    let i = '112.89.121.'
    let ara = Math.floor(Math.random() * 1000).toString()
    let ip = i.concat(ara)
    localStorage.setItem('userIp', JSON.stringify(ip))
    this.fetchWithRetry('https://ip.seeip.org/jsonip?')
      .then((response: Response) => response.json() as Promise<IPData>)
      .then(data => localStorage.setItem('userIp', JSON.stringify(data.ip)))
      .catch(error => console.error(error));
  }

  fetchWithRetry(url, options = {}, retries = 5, delay = 1000) {
    return new Promise((resolve, reject) => {
      let attempt = 1;

      const makeAttempt = () => {
        fetch(url, options)
          .then(response => {
            if (response.ok) {
              resolve(response);
            } else {
              throw new Error(response.statusText);
            }
          })
          .catch(error => {
            if (attempt < retries) {
              console.log(`Attempt ${attempt} failed, retrying in ${delay / 1000} seconds...`);
              attempt++;
              setTimeout(makeAttempt, delay);
            } else {
              reject(error);
            }
          });
      };

      makeAttempt();
    });
  }

}

<h6 class="headh6">Payment Status</h6>

<div class="container-fluid" style="overflow: hidden; max-height: 100vh">
  <div>
    <div>
      <ul class="nav nav-tabs" id="tabs-tab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="tabs-all-tab"
            data-toggle="pill"
            href="#tabs-all"
            role="tab"
            aria-controls="tabs-all"
            aria-selected="true"
            >Invoices({{ paymentDataLength }})</a
          >
        </li>
      </ul>

      <div class="positionCreateUpload">
        <button class="btnUpload mr-2" (click)="exportExcel()">
          <i class="fa fa-download" aria-hidden="true"></i>
        </button>
        <!-- <button class=" btnUpload mr-2" (click)="toCreateNew()"><span class="f-16">+</span> Create</button> -->
      </div>
      <!-- <div class="filter_input">
        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [showIcon]="true" placeholder="Select dates to Filter" [readonlyInput]="true" inputId="range"></p-calendar>
        <button class="btnUpload filter_btn_m "> Filter</button>
      </div> -->
    </div>

    <div class="tab-content" id="tabs-tabContent">
      <!-- All Tab -->
      <div
        class="tab-pane fade show active"
        id="tabs-all"
        role="tabpanel"
        aria-labelledby="tabs-all-tab"
      >
        <div class="tableDataDiv bg-design-all pt-1">
          <div>
            <div class="searchBar">
              <input
                class="searchInput"
                pInputText
                type="text"
                placeholder="Search + Enter"
                (keyup)="searchInvoiceDataV($event.target.value)"
                (input)="payment.filterGlobal($event.target.value, 'contains')"
              />
              <span class="material-icons searchIcon"> search </span>
            </div>
            <p-table
              #payment
              [value]="paymentData"
              [paginator]="showPaginator"
              [rows]="10"
              [showCurrentPageReport]="true"
              [showFirstLastIcon]="true"
              [globalFilterFields]="[
                'docheaderID',
                'documentPaymentStatus',
                'VenderId',
                'Vendername',
                'entity',
                'uploaded',
                'modified',
                'status',
                'amount'
              ]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              styleClass="p-datatable-striped"
              [rowsPerPageOptions]="[10, 25, 50]"
            >
              <ng-template pTemplate="header">
                <tr>
                  <!-- <th pSortableColumn="invoiceId">Invoice ID</th> -->
                  <th pSortableColumn="poNumber">Payment Ref No</th>
                  <th pSortableColumn="poNumber">Invoice Number</th>
                  <th pSortableColumn="date">Payment Date</th>
                  <th pSortableColumn="status">Status</th>
                  <!-- <th>Actions</th> -->
                </tr>
                <tr>
                  <td>
                    <input
                      pInputText
                      type="text"
                      (input)="
                        payment.filter(
                          $event.target.value,
                          'invoiceId',
                          'contains'
                        )
                      "
                      [value]="payment.filters['invoiceId']?.value"
                      placeholder="Search by Ref No"
                      class="p-column-filter tableSearchInput"
                    />
                  </td>
                  <td>
                    <input
                      pInputText
                      type="text"
                      (input)="
                        payment.filter(
                          $event.target.value,
                          'poNumber',
                          'contains'
                        )
                      "
                      [value]="payment.filters['poNumber']?.value"
                      placeholder="Search by Invoice no"
                      class="p-column-filter tableSearchInput"
                    />
                  </td>
                  <td>
                    <input
                      pInputText
                      type="text"
                      (input)="
                        payment.filter(
                          $event.target.value,
                          'poNumber',
                          'contains'
                        )
                      "
                      [value]="payment.filters['poNumber']?.value"
                      placeholder="Search by Payment Date"
                      class="p-column-filter tableSearchInput"
                    />
                  </td>
                  <td>
                    <input
                      pInputText
                      type="text"
                      (input)="
                        payment.filter(
                          $event.target.value,
                          'poNumber',
                          'contains'
                        )
                      "
                      [value]="payment.filters['poNumber']?.value"
                      placeholder="Search by Status"
                      class="p-column-filter tableSearchInput"
                    />
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-invoiceData>
                <tr>
                  <td>{{ invoiceData.data }}</td>
                  <td>{{ invoiceData.docheaderID }}</td>
                  <td></td>
                  <td class="statusType m-l-60" 
                    [ngStyle]="{'background-color': (invoiceData.documentPaymentStatus === 'paid') ? '#107e3e' : (invoiceData.documentPaymentStatus === 'Payment Pending') ? '#ff9494' : ''}">
                      {{invoiceData.documentPaymentStatus}}
                    </td>
                  <!-- <div *ngFor="let color of bgColorCode">
                    <td
                      style="width: 20%"
                      class="statusType f-12"
                      *ngIf="invoiceData.documentStatusID == color.id"
                      [ngStyle]="{
                        'background-color':
                          invoiceData.documentStatusID == color.id
                            ? color.bgcolor
                            : ''
                      }"
                    >
                      {{ invoiceData.documentPaymentStatus }}
                    </td>
                  </div> -->
                  <!-- <td >
                      <i class="pi pi-eye f-16 showEye"></i>
                    </td> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="10" class="p-t-30">
                    <img
                      src="assets/Group 2691.png"
                      width="260"
                      alt="imageUrl"
                    />
                    <br />
                    No Data found.
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="paginatorleft"> </ng-template>
              <ng-template pTemplate="paginatorright"> </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-vendor',
  templateUrl: './profile-vendor.component.html',
  styleUrls: ['./profile-vendor.component.scss']
})
export class ProfileVendorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

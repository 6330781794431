<h6 class="headh6">All Documents</h6>

<div class="container-fluid" style="overflow: hidden;max-height: 100vh;">

  <div>

    <div>

      <ul class="nav nav-tabs d-inline-block">
        <li class="nav-item mr-1">
          <a [ngClass]="route.url == '/vendorPortal/invoice/allInvoices'?'active-tab':''" class="nav-link"
            (click)="menuChange('invoice')">Invoice({{allInvoiceLength}})</a></li>
        <li class="nav-item mr-1">
          <a [ngClass]="route.url == '/vendorPortal/invoice/PO'?'active-tab':''" class="nav-link"
            (click)="menuChange('po')">Purchase Order({{poArrayLength}})</a>
        </li>
        <li class="nav-item mr-1"><a [ngClass]="route.url == '/vendorPortal/invoice/GRN'?'active-tab':''" class="nav-link"
            (click)="menuChange('grn')">GRN({{GRNArrayLength}})</a></li>
        <li class="nav-item mr-1"><a [ngClass]="route.url == '/vendorPortal/invoice/archived'?'active-tab':''" class="nav-link"
            (click)="menuChange('archived')">Archived(0)</a></li>
      </ul>



      <div class="positionCreateUpload">
        <button class=" btnUpload mr-2" (click)="exportExcel()"><i class="fa fa-download"
            aria-hidden="true"></i></button>
      </div>

      <div class="filter_input">
        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [showIcon]="true" placeholder="Select dates to filter"
          [readonlyInput]="true" inputId="range"></p-calendar>
        <button class="btnUpload filter_btn_m "> Filter</button>
      </div>
    </div>

    <div class="tableDataDiv bg-design-all pt-2">
      <div>
        <div>
          <app-all-invoices [users]="invoiceDispalyData" [invoiceColumns]="invoiceColumns"
            [showPaginatorAllInvoice]="showPaginatorAllInvoice" [columnsToDisplay]="columnstodisplayInvoice"
            [columnLength] = "allINVColumnLength"
            (searchInvoiceData)="searchInvoiceDataV($event)" (sideBarBoolean)="showSidebar($event)"
            *ngIf="route.url == '/vendorPortal/invoice/allInvoices'">
          </app-all-invoices>
          <app-all-invoices [users]="poDispalyData" [invoiceColumns]="poColumns" [columnsToDisplay]="columnstodisplayPO"
            [showPaginatorAllInvoice]="showPaginatorPOTable" (sideBarBoolean)="showSidebar($event)"
            [columnLength] = "POColumnLength"
            (searchInvoiceData)="searchInvoiceDataV($event)" *ngIf="route.url == '/vendorPortal/invoice/PO'">
          </app-all-invoices>
          <app-grn [users]="GRNDispalyData" [showPaginatorGRNTable]="showPaginatorGRNTable"
            (searchInvoiceData)="searchInvoiceDataV($event)" *ngIf="route.url == '/vendorPortal/invoice/GRN'"></app-grn>
          <app-pip [users]="receiptDispalyData" (searchInvoiceData)="searchInvoiceDataV($event)"
            *ngIf="route.url == '/vendorPortal/invoice/paymentInprogress'">
          </app-pip>
          <app-all-invoices [users]="archivedDisplayData" [invoiceColumns]="archivedColumns"
          [columnLength] = "ARCColumnLength"
            [columnsToDisplay]="columnstodisplayArchived" (searchInvoiceData)="searchInvoiceDataV($event)"
            (sideBarBoolean)="showSidebar($event)" *ngIf="route.url == '/vendorPortal/invoice/archived'"></app-all-invoices>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="createInvoice">
    <div>
      <span class="goBackIn" (click)="backToInvoice()"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i></span>
    </div>
    <app-view-invoice></app-view-invoice>
  </div>
</div>

<p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000">
  <h6 style="font-weight: 600;">Select Columns</h6>
  <ul class="pl-3 sidebar_height" cdkDropList (cdkDropListDropped)="onOptionDrop($event)">
    <li class="allCols" *ngFor=" let displayColumn of allColumns; let i = index" cdkDrag>
      <label class="container" [for]="i" cdkDragHandle>{{displayColumn.columnName}}
        <input type="checkbox" [id]="i" (change)="activeColumn($event,displayColumn)"
          [checked]="displayColumn.isActive == 1">
        <span class="checkmark"></span>
      </label>
      <i class="fa fa-bars dragCols" aria-hidden="true" cdkDragHandle></i>
    </li>
  </ul>

  <div class="btnFooterSave" mat-dialog-actions>
    <button class="btn btnVender bg-btn-cancel mr-4 clor" type="button" (click)="visibleSidebar2 = false">Cancel</button>
    <button class="btn btnVender bg-btn-success clor" (click)="updateColumnPosition()" cdkFocusInitial>Save</button>
  </div>

</p-sidebar>

<p-toast></p-toast>
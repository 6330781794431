<div class="tableDatDiv">
  <div> <button class="btnUpload dwnldBtn" (click)="downloadReport()">Download Report</button></div>
  <div class="filter_inputDash">
    <p-calendar
      [(ngModel)]="rangeDates"
      selectionMode="range"
      [minDate]="minDate"
      [maxDate]="maxDate"
      placeholder="Select dates to Filter"
      [readonlyInput]="true"
      [showIcon]="true"
      showButtonBar="true"
      inputId="range"
      (onClearClick)="clearDates()"
    ></p-calendar>
    <button class="btnUpload filter_btn_m" (click)="filterByDate(rangeDates)">Filter</button>
  </div>
  <div class="d-flex justify-content-between">
    <div
      class="card-div d-flex bg-1"
      [class.active_card]="tabName == 'Total'"
      (click)="choosepageTab('Total')"
    >
      <div class="img-wrapper">
        <!-- <i class="fa fa-check"></i> -->
        <mat-icon class="placement" svgIcon="vendor_up"></mat-icon>
      </div>
      <div>
        <div class="f-12">Total Exception Invoices</div>
        <div class="f-15">{{ totalInv }}</div>
      </div>
    </div>

    <div
      class="card-div d-flex bg-2"
      [class.active_card]="tabName == 'OCR'"
      (click)="choosepageTab('OCR')"
    >
      <div class="img-wrapper">
        <!-- <i class="fa fa-exclamation-triangle"></i> -->
        <mat-icon class="placement" svgIcon="vendor_pr"></mat-icon>
      </div>
      <div>
        <div class="f-12">OCR Queue</div>
        <div class="f-15">{{ OcrInv }}</div>
      </div>
    </div>

    <div
      class="card-div d-flex bg-3"
      [class.active_card]="tabName == 'Batch'"
      (click)="choosepageTab('Batch')"
    >
      <div class="img-wrapper">
        <mat-icon class="placement" svgIcon="vendor_rm"></mat-icon>
      </div>
      <div>
        <div class="f-12">ERP Soft push</div>
        <div class="f-15">{{ batchInv }}</div>
      </div>
    </div>

    <div
      class="card-div d-flex bg-4"
      [class.active_card]="tabName == 'ERP'"
      (click)="choosepageTab('ERP')"
    >
      <div class="img-wrapper">
        <mat-icon class="placement" svgIcon="vendor_rej"></mat-icon>
      </div>
      <div>
        <div class="f-12">ERP Exception</div>
        <div class="f-15">{{ ErpInv }}</div>
      </div>
    </div>
  </div>

  <div class="pt-1 tabDiv bg-design-all" [ngSwitch]="tabName">
    <!-- Total invoices Tab -->
    <div *ngSwitchCase="'Total'">
      <app-table
        [tableData]="totalTableData"
        [invoiceColumns]="columnsForTotal"
        [showPaginator]="showPaginatortotal"
        [columnsToFilter]="totalColumnField"
        [columnLength]="ColumnLengthtotal"
      >
      </app-table>
    </div>

    <!-- OCR invoices Tab -->
    <div *ngSwitchCase="'OCR'">
      <app-table
        [tableData]="OCRTableData"
        [invoiceColumns]="columnsForOCR"
        [showPaginator]="showPaginatorOCR"
        [columnsToFilter]="OCRColumnField"
        [columnLength]="ColumnLengthOCR"
      >
      </app-table>
    </div>

    <!-- Batch invoices Tab -->
    <div *ngSwitchCase="'Batch'">
      <!-- <app-table
        [tableData]="batchTableData"
        [invoiceColumns]="columnsForbatch"
        [showPaginator]="showPaginatorbatch"
        [columnsToFilter]="batchColumnField"
        [columnLength]="ColumnLengthbatch"
      >
      </app-table> -->
      <app-exception-table
        [invoiceColumns]="columnsForbatch"
        [columnsToDisplay]="batchColumnField"
        [showPaginatorAllInvoice]="showPaginatorbatch"
        [ColumnLength]="ColumnLengthbatch"
        (searchInvoiceData)="searchInvoiceDataV($event)"
        [columnsData]="batchTableData"
      >
      </app-exception-table>
    </div>

    <!-- ERP invoices Tab -->
    <div *ngSwitchCase="'ERP'">
      <app-table
        [tableData]="ERPTableData"
        [invoiceColumns]="columnsForERP"
        [showPaginator]="showPaginatorERP"
        [columnsToFilter]="ERPColumnField"
        [columnLength]="ColumnLengthERP"
      >
      </app-table>
    </div>
    <ngx-spinner
      bdColor="rgba(251, 251, 251, 0.8)"
      size="medium"
      color="#070900"
      [fullScreen]="false"
      type="ball-spin-clockwise"
    >
      <p style="color: rgb(0, 0, 0)"></p>
    </ngx-spinner>
  </div>
</div>

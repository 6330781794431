import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
viewBoolean:boolean;
editBoolean:boolean;
changeApproveBoolean:boolean;
financeApproveBoolean:boolean;

addUsersBoolean:boolean;
addUserRoleBoolean:boolean;
serviceTriggerBoolean: boolean;
  isSuperAdmin: boolean;
  dashboardUserBoolean: boolean;
  uploadPageAccess: boolean;
  constructor() { }
}

<h6 class="headh6">General Settings</h6>

<div class="bg-design-all tableDataDiv b-top-left m-2">
    <ul>
        <li *ngIf="isAdmin && finaceApproveDisplayBoolean">
            <a [routerLink]="'financeSettings'">Finance approval settings</a>
        </li>
        <!-- <li>
            <a [routerLink]="'changePassword'">Password settings</a>
        </li> -->
        <li>
            <a [routerLink]="'ServiceInvoicesBatchTriggerSettings'">Service-invoices Batch Trigger settings</a>
        </li>
        <!-- <li>
            <a [routerLink]="'VendorInvoicesBatchTriggerSettings'">Vendor-invoices Batch Trigger settings</a>
        </li> -->
    </ul>
</div>
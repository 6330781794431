export const comaprisionLineData = [{
    invoiceData:[
        { UnitPrice : 34, Quantity: 12, productCode: "DC123" },
        { UnitPrice : 4, Quantity: 2, productCode: "PS123" },
        { UnitPrice : 3, Quantity: 45, productCode: "DC1423" },
    ],
    poData: [
        { UnitPrice : 34, Quantity: 12, productCode: "DC123" },
        { UnitPrice : 4, Quantity: 2, productCode: "PS123" },
        { UnitPrice : 3, Quantity: 45, productCode: "DC1423" },
    ],
    GrnData: [
        { UnitPrice : 34, Quantity: 12, productCode: "DC123" },
        { UnitPrice : 4, Quantity: 2, productCode: "PS123" },
        { UnitPrice : 3, Quantity: 45, productCode: "DC1423" },
    ]


    // descrpt: {
    //     [
    //     invoice:{abc:"123"},
    //     po:{abc:"235"},
    //     grn:{abc:"23"}]
    //     [
    //     invoice:{abc:"23"},
    //     po:{abc:"23"},
    //     grn:{abc:"23"}
    //     ]
    //     [
    //     invoice:{abc:"235"},
    //     po:{abc:"23"},
    //     grn:{abc:"23"}
    //     ]
    // }

}]
<div style="display: flex;">
    <div class="sideBar">
        <!-- <div class="tittleVendor">
            Vendor Portal
        </div> -->
        <div class="logotitle">
            <div class="logoPlaceholder">
                <img class="logo_img" src="assets/serina.png" alt="logo" width="42" height="42">
            </div>
            <div class="placeLogo">
                Serina Plus
                <span class="portalName">Vendor Portal</span>
            </div>
        </div>
        <div class="routeLinks">
            
            <div class="navLink"  routerLinkActive="active" data-text="Dashboard" [routerLink]="'home'">
                <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                    home
                </span>
                Dashboard
            </div>

            <div class="navLink" routerLinkActive="active" data-text="Upload" [routerLink]="'uploadInvoices'">
                <!-- <i class="fa fa-cloud-upload f-18 ml-r-12" aria-hidden="true"></i> -->
                <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                    upload_file
                    </span>
                Upload
            </div>

            <div class="navLink" routerLinkActive="active" data-text="Document Status" [routerLink]="'invoice'">
                <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                    receipt
                </span>
                Document Status
            </div>
        
            <div class="navLink" routerLinkActive="active" data-text="Action Center" [routerLink]="'action-center'">
                <i class="fa fa-universal-access f-18 ml-r-12" aria-hidden="true" routerLinkActive="ml-9"></i>
                    Action Center
            </div>

            <div class="navLink" routerLinkActive="active" data-text="Payment Status" [routerLink]="'payment-details-vendor'">
                <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                    monetization_on
                    </span>
                    Payment Status
            </div>

            <div *ngIf="addUsersBoolean" class="navLink" data-text="Roles" routerLinkActive="active" [routerLink]="'vendorUsers'">
                <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                    manage_accounts
                    </span>
                    Roles
            </div>

            <div *ngIf="addUsersBoolean" class="navLink"  data-text="Contact Details" routerLinkActive="active" [routerLink]="'vendorContacts'">
                <i class="fa fa-address-book f-18 ml-r-12" aria-hidden="true" routerLinkActive="ml-9"></i>
                    Contact Details
            </div>
            
        </div>
        <div class="copy">Copyrights <span class="cpr f-14">&#169;</span> 2024</div>
    </div>
    <div class="bodyContent">
        <div class="f-right">
            <span class="userName f-11">
                <span>Last login :&nbsp; </span>  {{last_login + 'Z' |  date:'medium'}} &nbsp; &nbsp;
              </span>
            <span class="b-right" style="padding-right:14px ;cursor: pointer;" [routerLink]="'notifications'">
                <i class="pi pi-bell p-mr-4 p-text-secondary f-18" [value]="numberOfNotify" pBadge></i></span>

            <span class="ml-2 dropdown" (clickOutside)="onClickedOutside($event)" (click)="isActive()">
                <!-- <span class="material-icons top-9 ">
                    keyboard_arrow_down
                </span> -->
                <i class="fa fa-user-circle-o profileIcon" aria-hidden="true"></i>
                <!-- <img src="/assets/maskGroup89.png" alt="Logo" width="40" height="40" style="border-radius: 50px;"> -->

            </span>
            <span class="b-left-sky clr userName">{{userDetails.userdetails.firstName}}

            </span>
            <!-- <span class="material-icons sign_out" (click)="displayResponsivepopup = true">
                power_settings_new
                </span> -->
            <mat-icon class="sign_out" title="Sign Out"  svgIcon="logout" (click)="displayResponsivepopup = true"></mat-icon>
                        <!-- <i class="fa fa-sign-out sign_out" title="Sign Out" (click)="displayResponsivepopup = true" aria-hidden="true"></i> -->
            <!-- <div class="dropdown-content" *ngIf="showLogout"> -->
                <!-- <a [routerLink]="'profile'">My Profile</a> -->
                <!-- <a (click)="logout()">Logout</a> -->
            <!-- </div> -->
            
        </div>
        <router-outlet></router-outlet>
    </div>
</div>

<!-- Cnfirmation Dialog -->
<p-dialog header=" " [(visible)]="displayResponsivepopup" [breakpoints]="{'960px': '75vw'}" [style]="{width: '30vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="text-center">
    <div>
      <i class="pi pi-exclamation-triangle" style="font-size: 4rem;color: red;"></i>
    </div>
    <div class="deleteDivText">
      {{BtnText}}
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
        <button class="btn btnVender bg-btn-success mr-3" (click)="logout()">Yes</button>
        <button class="btn btnVender bg-btn-cancel" (click)="displayResponsivepopup=false">Cancel</button>
    </div>

  </ng-template>
</p-dialog>

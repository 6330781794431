<h6 class="headh6 p-l-50">OCR Configuration</h6>
<div>
    <ul class="nav nav-tabs pr-2 pl-2" id="myTab" role="tablist">
      <li class="nav-item mr-2">
        <a class="nav-link active b-l-active" id="action-tab" data-toggle="tab" href="#action" role="tab" aria-controls="action" aria-selected="true">Actions & Templates (Rhythm)</a>
      </li>
      <li class="nav-item">
        <a class="nav-link b-r-active" id="config-tab" data-toggle="tab" href="#config" role="tab" aria-controls="config" aria-selected="false">Configuration</a>
      </li>
    </ul>

    <div style="overflow: hidden;" class="tab-content bg-design-all pb-2 pr-2 pl-2 ml-2 mr-2 " id="myTabContent">

      <!-- action template -->
      <div class="tab-pane fade show active overFlow   " id="action" role="tabpanel" aria-labelledby="action-tab">
        <!-- <div class="row">
          <div class="col-sm-6">
            <input type="text" placeholder="subject" [innerHTML]='emailSubject' [(ngModel)]="emailSubject" class="form-control">
          <label for="emailT" class="labelBody">Email Body</label>
         
          <textarea id="emailT" name="emailT" [(ngModel)]="emailBody"  style="min-height: 100px;" class="form-control">{{emailBody}}</textarea>
          </div>

          <div class="col-sm-6">

            <h6>Preview For Mail Template</h6>
            <div class="f-13" [innerHTML]='emailBody'>
            
            </div>
          </div>
        </div>  
        
        
          
          <div  class="btnMr">
            <button class="btn btnVender mr-3" >Cancel</button>
            <button class="btn btnVender" (click)="updateTemplate()" >Save</button>
          </div> -->
          
            <div class="row ">
              <div class="col-sm-11">


              <div >
                <div class="d-flex justify-content-end"> 
                  <label class="labelText mr-2 mt-1">Recipients</label>
                  <p-multiSelect [options]="recipitentsList" [(ngModel)]="selectedRecipients" defaultLabel=""
                    optionLabel="mail" selectedItemsLabel="{0} items selected" (onChange)="selectedPO($event)">
                  </p-multiSelect>
                </div>
                <!-- <mat-accordion>
                  <div class="row"  *ngFor="let value of actions; let i = index;">
                    <div class="col-sm-1 checkPlace1">
                      <label class="custom-checkbox">
                        <input type="checkbox" class="mr-2 checkPlace" value=""/>
                        <i class="material-icons f-18 unchecked" style="color: #D0D0D0;">verified</i>
                        <i class="material-icons f-18 checked" style="color: #19B600;">verified</i>
                      </label>
                    </div>
                    <div class="col-sm-11">
                      <mat-expansion-panel 
                       (opened)="setOpened(i)"
                       (closed)="setClosed(i)" style="margin-top: 10px;">
                       
                       <mat-expansion-panel-header class="KPIName">
                         <mat-panel-title class=" secondheadertoggle">
                           
                           <h6 class="kpi-header f-13 faPulsandMinus head-pos">
                             {{ value }}
                           </h6>
                          
                         </mat-panel-title>
                       </mat-expansion-panel-header>
                       <ng-container>
                         <div>
                 
                           <div>
                             <div class="d-flex justify-content-between">
                               <label class="custom-checkbox labelPlace">
                                <input type="checkbox" />
                                <i class="material-icons f-16 unchecked" style="color: #D0D0D0;">verified</i>
                                <i class="material-icons f-16 checked" style="color: #19B600;">verified</i>
                                <span class="ml-2 labelTextT">Email</span>
                              </label>

                              <div class="d-flex ">

                                <div class="d-flex">
                                  <label class="labelText mr-2 mt-1">Priority</label>
                                  <select class="form-control selectDp inputBox" [(ngModel)]="SelectedPriority">
                                    <option *ngFor="let type of priority" [value]="type">{{type}}</option>
                                  </select>
                                </div>
  
                                
                              </div>
                             </div>
                             <div>
                 
                               <textarea id="emailT" 
                                         name="emailT" 
                                         [(ngModel)]="emailBody"  
                                         style="min-height: 100px;" 
                                         class="form-control"
                                         >
                                         {{emailBody}}
                               </textarea>
                             </div>
                           </div>
                 
                           <div class="mt-2">
                             <div class="d-flex justify-content-between">
                             <label class="custom-checkbox labelPlace">
                              <input type="checkbox" />
                              <i class="material-icons f-16 unchecked" style="color: #D0D0D0;">verified</i>
                              <i class="material-icons f-16 checked" style="color: #19B600;">verified</i>
                              <span class="ml-2 labelTextT">Notification</span>
                            </label>
                             </div>
                             <div>
                          
                               <textarea id="NtT" 
                                         name="NtT" 
                                         [(ngModel)]="smsBody"  
                                         style="min-height: 100px;" 
                                         class="form-control"
                                         >
                                         {{smsBody}}
                               </textarea>
                             </div>
                           </div>
                 
                         </div>
                       
                         
                         <button class="btnHelp" (click)="visibleSidebar2 = true"> Help <i class="fa fa-question-circle" aria-hidden="true"></i></button>
                         <button class="btnHelp bg-btn-sm ml-2" (click)="viewTemplate = true"> Preview</button>
                       </ng-container>
                 
                     </mat-expansion-panel>
                    </div>
                  </div>
                 
                  
                </mat-accordion> -->
                <!-- <div  class="actiontypeBtn mt-4">
                  <button class="btn btnVender mr-3" type="button" >Cancel</button>
                  <button class="btn btnVender" type="submit" >Save</button>
                </div> -->
              </div>

              </div>

            </div>


        
      </div>

      
      <!-- Confuration  -->
      <div class="tab-pane fade overFlow " id="config" role="tabpanel" aria-labelledby="config-tab">
      <div class="mt-3">
        <!-- <div *ngFor='let value of config'>
          <ul class="ulF-14" *ngFor = " let item of value | keyvalue">
            {{item.key}}
            <div class="mt-2"  *ngFor = "let type of item.value ">
              <input type="text" class="form-control" style="width: 30%;" [value]="type">
            <div style="font-size: 12px;font-weight: normal;" >Description: some Descrption for each element</div>
            </div>
          
          </ul>
        </div> -->
        <form #form="ngForm" (ngSubmit)="submit()">
          <div>
            <div class="ulF-14">Key Labels List</div>
            <ul>
              <li class="f-12" *ngFor="let tag of Tags">{{tag}} </li>
            </ul>
          </div>
          <div  class="mt-2">
            <div class="ulF-14">Confidance Threshold 
              <input type="text" name="cThreshold" ngModel  #Cthreshold="ngModel" [(ngModel)]="cThreshold" style="width: 30%;" class="form-control mt-1">
            </div>
            <div class="f-12" >Description: some Description for each element</div>
          </div>
          <div  class="mt-2">
            <div class="ulF-14">Tolerance Level per Invoice <input type="text" style="width: 30%;" class="form-control mt-1" name="toleranceIn" ngModel  #ToleranceIn="ngModel" [(ngModel)]="TolerancePerInv" ></div>
            <div class="f-12" >Description: some Description for each element</div>
          </div>
          <div class="mt-2">
            <div class="ulF-14">Tolerance Level per Vendor <input type="text" style="width: 30%;" class="form-control mt-1" name="toleranceVendor" ngModel  #ToleranceVendor="ngModel" [(ngModel)]="TolerancePervendor"  ></div>
            <div class="f-12" >Description: some Description for each element</div>
          </div>

          <!-- <div  class="actiontypeBtn">
            <button class="btn btnVender mr-3" type="button" >Cancel</button>
            <button class="btn btnVender" type="submit" >Save</button>
          </div> -->

        </form>
      </div>
      </div>

      <div  class="actiontypeBtn">
        <button class="btn btnVender bg-btn-success mr-3" >Save</button>
        <button class="btn btnVender bg-btn-cancel" >Cancel</button>

      </div>
    </div>
</div>

<p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000">
  <div>
    <h6 style="font-weight: 600;"> 
      Tags
    </h6>
    <div class="searchBar">
      <input class="searchInput form-control"  style="height: 30px;" type="text" name="search" [(ngModel)]="filterString" (ngModelChange)="onFilterChange()"  placeholder=" Start searching " />
      <!-- <span class="material-icons searchIcon">
        search
        </span> -->
      </div>
    <ul>
      <li style="font-size:13px;font-weight: 600;" *ngFor="let tag of filtered">
        {{tag}}
        <div style="font-size: 12px;font-weight: normal;" > some Description for each element</div>
      </li>
    </ul>
  </div>
  
</p-sidebar>


<!-- Modal -->
<!-- <div class="modal fade" id="previewMail" tabindex="-1" role="dialog" aria-labelledby="previewMailTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Email Template Preview</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="font-size: 12px;"> 
        <div [innerHTML]='emailBody'>
          {{emailBody}}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btnVender" data-dismiss="modal">Close</button>
       
      </div>
    </div>
  </div>
</div> -->

<!-- template view -->
<p-dialog header="Email Template Preview " [(visible)]="viewTemplate" [breakpoints]="{'960px': '75vw'}" [style]="{width: '35vw'}"
  [baseZIndex]="100000" [draggable]="false" [resizable]="false">
  <div class="f-12"> 
    <div [innerHTML]='emailBody'>
      {{emailBody}}
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button class="btn btnVender bg-btn-success" (click)="viewTemplate=false">Close</button>

  </ng-template>
</p-dialog>


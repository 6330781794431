<h6 class="headh6"> 
    <span class="goBackIn" (click)="backToSettings()">
      <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
    </span>

    <span class="ml-36"> Finance Approval Settings</span>
</h6>
<div style="overflow: hidden;" class="bg-design-all tableDataDiv b-top-left m-2 p-2">
  <div class="row">
    <div class="col-md-6">

      <div class=" mb-3 ml-4">
        <div class="permisionDiv">
          <div> <span class="f-13">Role based approval</span>
            <span class="f-right">
              <label class="switch">
                <input type="checkbox" [(ngModel)]="financeApproveBoolean" [checked]="financeApproveBoolean" (change)="changeFinanceApproveSettings($event)">
                <span class="slider round"></span>
              </label>
            </span>
          </div>
        </div>
        <div class="permisionDivdescription">
          Finance approvals done based on roles, when it's enabled(irrespective of ammount).
        </div>
      </div>

    </div>
  </div>

</div>
<p-toast></p-toast>
<div class="text-center center_absolute">
    <h5 class="font_weight">Change Password</h5>
  </div>
  <div mat-dialog-content>
    <form
      #changePassword="ngForm"
      (ngSubmit)="savePassword(changePassword.value)"
      class="formDiv"
    >
      <label class="f-13">Old Password </label>
      <input
        type="password"
        name="oldPassword"
        ngModel
        #oldPass="ngModel"
        class="f-13 form-control "
        autocomplete="off"
        required
      />
      <label class="f-13">New Password </label>
      <input
        type="text"
        name="newpassword"
        ngModel
        #newPass="ngModel"
        class="f-13 form-control"
        minlength="8"
        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':\\|,.]).{8,}"
        (keyup)="checkPattren(newPass.value)"
        autocomplete="off"
        required
      />
      <div
      *ngIf="newPass.invalid && (newPass.touched || newPass.dirty)"
      class="alertDiv"
    >
      <div [hidden]="!newPass.errors?.pattern">
        <ul>
          <li class="checkColor">Atleast 1 number required</li>
          <li class="checkColor">Atleast 1 lowercase letter required</li>
          <li class="checkColor">Atleast 1 uppercase letter required</li>
          <li class="checkColor">Atleast 1 special character required</li>
          <li class="checkColor">Minimum 8 characters required</li>
        </ul>
      </div>
      <div [hidden]="!newPass.errors.required">**Required field</div>
    </div>
      <label class="f-13">Confirm Password </label>
      <input
        type="password"
        name="confirmPassword"
        ngModel
        #confirmPass="ngModel"
        class="form-control f-13"
        (keyup)="testPassword(newPass.value,confirmPass.value)"
        autocomplete="off"
        required
      />
      <div class="alert alert-danger f-12" *ngIf="passwordMatchBoolean">
        Please enter the same password.
      </div>

      <div class="mt-4 d-flex justify-content-center mb-3">
        <button
          class="btn btnVender bg-btn-success mr-3"
          type="submit"
          [disabled]="changePassword.invalid"
        >
          Save
        </button>
        <button class="btn btnVender bg-btn-cancel" type="button" mat-button mat-dialog-close>
          Cancel
        </button>
      </div>
    </form>
  </div>
